@import "../shared";


$desktop: 1025;
$fullScreenTable: 1256;


.root {
	flex-grow: 1;
	display: flex;
	max-width: 1400px;
	margin: 0 auto;
	padding-left: 40px;
	flex-direction: row;
	width: 100%;
	background-color: transparent;

	@include max(1400) {
		padding-left: 0;
	}

	@include max($desktop) {
		max-width: 100%;
	}

	.breadcrumb:global(.ant-breadcrumb) {
		padding-left: 39px;
		top: -20px;
		position: relative;

		:global(.ant-breadcrumb-link), a {
			color: #fff;
		}

		:global(.ant-breadcrumb-separator) {
			color: #fff;
		}

		li:last-child a {
			color: #CC78FF;
		}

		@include max(1024) {
			padding-left: 0;
		}
	}
}

.showFilterBtn {
	margin-right: 16px;
	margin-left: 16px;
}

.content {
	flex-grow: 1;
	width: calc(100% - 318px);
	padding-top: 50px;

	button.searchesDropdown, .searchesDropdown button {
		min-width: 150px;
		border: 2px solid #CC78FF;
		border-radius: 8px;
		color: #FFFFFF;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		text-align: center;
		letter-spacing: 0.175px;
		background-color: transparent;
		height: 42px;
		transition: .3s;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-shrink: 1;
		order: 5;

		span {
			overflow: hidden;
			text-overflow: ellipsis;
			line-height: 18px;
		}

		&[disabled] {
			opacity: .5;
		}

		&.active {
			border-color: transparent;
			background-color: #9e00ff;
		}

		@include max($desktop) {
			height: 34px;
			font-size: 14px;
			max-width: 173px;

			svg {
				width: 20px;
				margin-left: 4px;
			}
		}
	}
}

.saveSearchBtn {
	@extend .btnFilled;
	height: 42px;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	margin-left: 16px;
	order: 3;
	display: inline-flex;
	align-items: center;
	gap: 8px;
}

.saveSearchBtnCancel {
	@extend .btnTransparent;
	padding: 12px;
	height: 42px;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
}

.saveSearchPopover {
	width: 340px;
	max-width: calc(100vw - 16px);

	:global(.ant-popover-inner) {
		background: #423072;
		box-shadow: 0 34px 80px rgba(0, 0, 0, 0.08);
		border-radius: 8px;
	}

	&:global(.ant-popover-placement-bottomRight .ant-popover-content .ant-popover-arrow) {
		border-color: #423072;
	}

	:global(.ant-popover-inner-content) {
		padding: 21px 26px;
		width: 100%;
	}
}

.searchesMenuContainer {
	display: flex;
	flex-direction: column;
	min-height: 250px;

	ul {
		list-style: none;
		display: flex;
		flex-direction: column;
		padding-left: 0;
		margin-bottom: 0;
		height: 250px;
		overflow-y: auto;
		padding-right: 12px;
		@include scrollStyle(#30254c);
	}

	.spin {
		align-self: center;
		margin: 16px;
	}

	.searchesMenuItem {
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #CFCFCF;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: 0.175px;
		margin-bottom: 16px;
		cursor: pointer;

		&.active, &:hover {
			color: #CC78FF;

			.deleteSearch path {
				fill: #CC78FF;
			}
		}
	}

	.searchesMenuTitle {
		font-size: 16px;
		line-height: 16px;
		letter-spacing: 0.175px;
		color: #FFFFFF;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 25px;

		:global(.anticon) {
			position: absolute;
			right: 21px;
			top: 27px;
		}
	}

	.manageSearches {
		background-color: #9E00FF;
		border-radius: 8px;
		width: 100%;
		border: none;
		color: #FFFFFF;
		height: 42px;
		margin-top: auto;
	}
}

.spinContainer {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9;
}

.hiddenSeo {
	display: none;
}

.buttons {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	z-index: 10;
	padding: 8px 0 8px 39px;
	gap: 16px 8px;

	.sortingBtn, .filtersBtn {
		border: 1px solid #CC78FF;
		box-sizing: border-box;
		border-radius: 8px;
		display: inline-flex;
		font-weight: 500;
		font-size: 14px;
		text-align: center;
		letter-spacing: 0.175px;
		color: #FFFFFF;
		font-family: var(--font-poppins);
		background-color: transparent;
		line-height: 34px;
		padding: 0 16px;
		align-items: center;
		height: 42px;

		@include min($fullScreenTable) {
			display: none;
		}
		@include max($desktop) {
			height: 34px;
		}
	}


	.sortIcon {
		width: 11px;

		svg {
			width: 11px;
		}
	}

	.filterIcon {
		margin-left: 8px;
		color: #fff;
		width: 1em;
	}

	.saveSearchBtn {
		margin-right: 0;
		margin-left: auto;

		@include max($desktop) {
			height: 34px;
		}
	}

	@include max($fullScreenTable) {
		top: 57px;
		padding-right: 16px;
		padding-left: 16px;
		position: sticky;
		background-color: #271e4285;
		backdrop-filter: blur(25px);
		width: 100vw;
		transform: translateX(-16px);
	}
	@include max($desktop) {
		margin-bottom: 8px;
		padding-left: 16px;
	}

	@include min($fullScreenTable) {
		.sortingContainer {
			display: none;
		}
	}
	@include max($mobileSwitch) {
		transform: translateX(-8px);
		padding-left: 8px;
		padding-right: 8px;
	}
	@include max(768) {
		display: grid;
		grid-template-columns: [col] auto [col] 1fr [col] auto;
		grid-template-rows: [row] auto [row] auto;

		.total {
			grid-column: col 1 / span 2;
			grid-row: row 2;
		}
		.searchesDropdown {
			grid-column: col 2 / span 2;
			grid-row: row 2;
			margin-left: auto;
		}
		.filtersBtn {
			grid-column: col 1;
			grid-row: row;
		}
		.sortingContainer {
			grid-column: col 2 / span 2;
			grid-row: row;
		}
		.saveSearchBtn {
			grid-column: col 2 / span 2;
			grid-row: row;
		}
	}

	@include max(400) {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		> * {
			max-width: calc(50% - 4px);
			flex-basis: 50%;
		}
		.sortingContainer {
			flex-basis: 50%;
		}

		.total {
			order: 5;
		}
		.saveSearchBtn {
			margin-left: 0;
			justify-content: center;
		}
		.sortingBtn {
			width: 100%;
			justify-content: center;
		}
		button.searchesDropdown {
			order: 4;
			max-width: calc(50% - 4px);
			min-width: 140px;
		}
		.filtersBtn {
			justify-content: center;
		}
	}
}

.headerText {
	padding: 0 0 0 39px;
	background-color: transparent;
	font-size: 16px;
	line-height: 140%;
	letter-spacing: 0.0583333px;
	color: #CFCFCF;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;
	justify-content: space-between;

	.limitContent {
		max-width: 890px;
	}

	&.headerTextWithAd {
		display: grid;
		grid-template-columns: 1fr 240px;
		gap: 20px;
		align-items: center;

		@include max(768) {
			grid-template-columns: 1fr;
			.listingHeaderAd {
				display: none;
			}
		}
	}

	.listingHeaderAd {
		img {
			width: 100%;
			height: auto;
		}
	}

	@include max($fullScreenTable) {
		padding-left: 0;
	}

	@include max($desktop) {
		margin-bottom: 18px;
		width: 100%;

		h1 {
			font-family: var(--font-lora);
			font-weight: 500;
			font-size: 24px;
			line-height: 28px;
		}

		.limitContent {
			font-family: var(--font-poppins);
			font-size: 12px;
			line-height: 140%;
			letter-spacing: 0.0583333px;
		}
	}

	h1 {
		color: #FFFFFF;
		font-family: var(--font-lora);
	}
}


.total {
	font-size: 12px;
	line-height: 18px;
	letter-spacing: 0.5px;
	color: #CC78FF;
	flex-shrink: 0;
	flex-grow: 1;
	order: -1;
}

.cardContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	position: relative;
	min-height: 20vh;
	gap: 16px;
	padding-bottom: 60px; // space for spinner

	@include min($desktop) {
		gap: 0;
		justify-content: flex-start;
		flex-direction: column;
	}
}

.cardContainerIntersection {
	position: relative;
	width: 100%;
	height: 10px;
}

.listingInRowAd {
	min-height: 140px;
	width: 100%;
	position: relative;

	img {
		border-radius: 8px;
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;
		display: none;
	}

	.adDesktop {
		display: block;
		padding: 0 0 0 40px;

		@include max(1256) {
			padding: 0;
		}
	}

	@include max(1024) {
		max-width: 375px;
		min-height: 334px;
		.adDesktop {
			display: none;
		}
		.adMobile {
			display: block;
		}
	}
}

.card {
	color: #FFFFFF;
	box-shadow: 0 34px 80px rgba(0, 0, 0, 0.08);
	flex-grow: 1;
	position: relative;
	max-width: 375px;
	width: 100%;

	@include max(375) {
		margin-left: 0;
		margin-right: 0;
	}

	.cardBody {
		border-radius: 8px;
		padding: 10px 12px 16px;
		background-color: #30254C;
		position: relative;
		z-index: 2;
	}

	.cardHeader {
		display: flex;
		align-items: center;
		margin-bottom: 12px;
	}
	.cardHeaderLeft {
		display: flex;
		flex-wrap: wrap;
		flex-grow: 1;
		gap: 4px 20px;
		align-items: center;
	}

	.cardHeaderId {
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		font-family: var(--font-lora);
	}

	.cardStatusWrap {
		display: flex;
		flex-wrap: wrap;
		gap: 4px;
	}

	.cardTitle {
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: 0.47px;
		color: #CC78FF;
		margin-bottom: 12px;
		display: flex;
		font-family: var(--font-lora);
	}

	.originImg {
		margin-left: 8px;
	}

	.cardPropsList {
		list-style: none;
		padding: 0;
		margin-bottom: 20px;
		gap: 8px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		li {
			font-family: var(--font-poppins);
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 19px;
			letter-spacing: 0.0583333px;
			color: #CFCFCF;
			display: flex;;
			justify-content: space-between;
		}

		.statusCell, .resortCell, .favoriteCell {
			display: none;
		}

		span:not(.statusBtn) {
			font-weight: 400;
			font-size: 14px;
			color: #CFCFCF;

			@include max($desktop) {
				color: #FFFFFF;
			}
		}
	}

	.cardFooter {
		display: flex;
		align-items: center;

		hr {
			flex-grow: 1;
			border: none;
			border-top: 1px solid #423072;
		}

		.viewDetails {
			font-family: var(--font-poppins);
			font-size: 14px;
			line-height: 19px;
			letter-spacing: 0.0583333px;
			color: #CC78FF;
			margin-left: 16px;
		}
	}

	@include min($desktop) {
		max-width: none;
		box-shadow: none;
		cursor: pointer;
		border-bottom: 1px solid #30254c;
		flex-grow: 0;

		.cardBody {
			min-height: 70px;
			border-radius: 0;
			padding-left: 40px;
			padding-right: 0;
			background-color: transparent;
			transform-origin: center center;
			background-size: 0 100%;
			transition: .3s;
			background-repeat: no-repeat;
			background-image: linear-gradient(90deg, rgba(214, 200, 255, .1) 1.63%, rgba(39, 30, 66, 0) 100%);

			&:hover {
				background-size: 100% 100%;
				transform: scale(1.02);
			}
		}
		.cardHeader {
			display: none;
		}
		.cardFooter {
			display: none;
		}
		.cardTitle {
			display: none;
		}

		.cardPropsList {
			align-items: center;
			flex-direction: row;
			gap: 0;
			margin-bottom: 0;

			li {
				font-size: 0;
				justify-content: center;
				flex-shrink: 0;

				&.resortCell {
					width: 110px;
					flex-grow: 1;
					max-width: 320px;
					font-size: 16px;
					justify-content: flex-start;
					display: flex;
					align-items: center;
					position: relative;
					padding-right: 8px;

					a {
						color: #FFF;
						display: block;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						height: 45px;
						line-height: 45px;
						vertical-align: middle;

						&:hover, &:visited {
							color: #c278f8
						}

						span {
							font-size: 11px;
							color: #FFF;
							padding: 0 5px;
							vertical-align: middle;
							border-radius: 4px;
							overflow: visible;
							line-height: 14px;
							margin-left: 4px;
							position: absolute;
							left: -9px;
							bottom: -6px;
							display: flex;
							align-items: center;
						}
					}
				}

				&.pointsOnCell {
					justify-content: flex-start;
					width: 50px;
				}

				&.pointsCell {
					width: 137px;
					display: flex;
				}

				&.pppCell {
					width: 73px;
				}

				&.useCell {
					justify-content: flex-start;
					width: 81px;
				}

				&.priceCell {
					justify-content: flex-start;
					width: 90px;
				}

				&.starsCell {
					justify-content: flex-start;
					width: 126px;
				}

				&.ageCell {
					justify-content: flex-start;
					width: 80px;
				}

				&.statusCell {
					width: 120px;
					display: flex;
					justify-content: flex-start;
					align-items: flex-start;
					flex-direction: column;
					gap: 4px;
					padding-right: 4px;
				}

				&.favoriteCell {
					width: 40px;
					display: flex;
				}
			}
		}
	}

	@include max($fullScreenTable) {
		.cardBody {
			padding-left: 8px;
		}
	}
}

.tableHeader {
	padding-left: 40px;
	width: 100%;
	background-color: #30254c;
	height: 44px;
	display: none;
	border-radius: 0 8px 8px 0;
	position: sticky;
	top: 56px;
	z-index: 9;
	margin-bottom: 30px;

	@include min($desktop) {
		display: flex;
		justify-content: space-between;
	}
	@include max($fullScreenTable) {
		border-radius: 0;
		margin-left: -16px;
		margin-right: -16px;
		width: calc(100% + 32px);
		top: 114px;
		padding-left: 15px;
	}

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		font-weight: 500;
		color: #FFF;
		font-size: 14px;
		line-height: 19px;
		letter-spacing: 0.058px;
		gap: 16px;

		&[data-field] {
			cursor: pointer;
		}
	}

	.resortCell {
		width: 110px;
		max-width: 320px;
		flex-grow: 1;
		justify-content: flex-start;
	}

	.pointsOnCell {
		justify-content: flex-start;
		width: 50px;
	}

	.pointsCell {
		width: 137px;
		display: flex;
	}

	.pppCell {
		width: 73px;
	}

	.useCell {
		width: 81px;
		justify-content: flex-start;
	}

	.starsCell {
		width: 126px;
		justify-content: flex-start;
	}

	.priceCell {
		width: 90px;
		justify-content: flex-start;
	}

	.ageCell {
		width: 80px;
		justify-content: flex-start;
	}

	.statusCell {
		width: 120px;
		display: flex;
		justify-content: flex-start;
	}

	.favoriteCell {
		width: 40px;
		display: flex;
	}
}

.subColumn {
	height: 20px;
}

.tableRow {
	cursor: pointer;
}

.likeButton {
	background-color: transparent;
	border: none;
	padding: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	flex-shrink: 0;

	svg {
		width: 20px;
	}

	&[data-active=true] {
		path {
			fill: #CC78FF;
		}
	}
}

.statusBtn {
	border: 1px solid var(--statusColor);
	box-sizing: border-box;
	display: inline-block;
	font-size: 12px;
	font-weight: 400;
	line-height: 16.174px;
	letter-spacing: 0.05px;
	border-radius: 8px;
	color: #CFCFCF;
	padding: 3.405px 12.769px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
}

.viewDetails {
	color: #CC78FF;
	position: relative;

	&:before {
		display: block;
		content: "";
		position: absolute;
		top: 100%;
		width: 100%;
		left: 0;
		height: 1px;
		border-radius: 2px;
		transform: translateY(5px);
		opacity: 0;
		transition: .3s;
		background-color: #CC78FF;
	}

	&:hover {
		color: #CC78FF;

		&:before {
			opacity: 1;
			transform: translateY(0);
		}
	}
}

.loadMoreContainer {
	padding: 16px;
	text-align: center;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 2;

	:global(.ant-spin-container::after) {
		background-color: #271e42;
	}

	:global(.ant-spin-dot-item) {
		background-color: #c278f8;
	}
}

.toTop {
	font-size: 24px;
	color: white;
	background-color: #1890ff;
	padding: 6px;
	border-radius: 100%;
}

.overNotificationButtons {
	display: flex;
	margin-top: 8px;
	justify-content: center;

	button {
		margin: 8px;
	}
}

.globalSpinner {
	// margin-top: 20vh;
}

.deleteSearch {
	margin-right: 0 !important;
	margin-left: 8px;
}

.sortingBtn {
	background-color: transparent;
	color: #c278f8;
	border: none;

	&:hover, &:focus, &:active {
		color: #FFFFFF;
		background-color: transparent;
	}
}

.sortingMenu {
	background-color: #423072;
	box-shadow: 0 34px 80px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	padding: 18px 0;
	width: 300px;

	li {
		color: #CFCFCF;
		font-family: var(--font-poppins);
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 27px;
		letter-spacing: 0.47px;
		padding: 0 24px;

		&:global(.ant-dropdown-menu-item-disabled) {
			opacity: 0.5;
		}

		strong {
			color: #FFFFFF;
			font-weight: 500;
			transition: .3s;
		}

		&:hover, &:focus, &:active, &.sortingMenuSelected {
			background-color: transparent;
			color: #CFCFCF;

			&:not(:global(.ant-dropdown-menu-item-disabled)) {
				color: #CC78FF;

				strong {
					color: #CC78FF;
				}
			}
		}
	}
}

.sortingMenuOverlay {
	:global(.ant-dropdown-arrow) {
		border-color: #423072!important;
		background: #423072!important;
	}
}

.originImg {
	border-radius: 50%;
}

.saveButtonPopover {
	@include popoverStyle(#3b3060, 16px);
	width: 314px;
	padding: 0;
	z-index: 100;

	.saveButtonPopoverContent {
		padding: 16px 24px;
		width: 240px;

		> div {
			margin-bottom: 12px;
			font-size: 18px;
			line-height: 19px;
		}

		> p {
			font-size: 14px;
			line-height: 140%;
			letter-spacing: 0.0583333px;
			color: #CFCFCF;
			margin-bottom: 12px;
		}

		.checkText {
			font-weight: 400;
			font-size: 12px;
			line-height: 22px;
			letter-spacing: 0.0583333px;
			color: #CC78FF;
		}
	}
}

.saveButtonPopoverClose {
	background-color: transparent;
	border: none;
	position: absolute;
	right: 15px;
	top: 15px;
	cursor: pointer;
}

